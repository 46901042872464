const Web3Eth = require('web3-eth');
const ETHERSCAN_URL= {
  '0x1': 'http://etherscan.io',
  '0x3': 'http://ropsten.etherscan.io',
  '0x4': 'https://rinkeby.etherscan.io',
  '0x42': 'http://kovan.etherscan.io'
};

let abi = {};
let contracts = {};
let currentAccount = {};
let networkId;
let web3Eth;

function lookupAddress(address) {
  let result = address;
  if(registry[address]) {
    result = registry[address];
  };
  return result;
}

function lookupName(name) {
  let result = name;
  Object.keys(registry).forEach(key => {
    if(registry[key] == name) {
      result = key;
    }
  });

  return result;
}

function isAddressValid(address) {
  return address.length == 42 && address.startsWith('0x');
}

function isHashValid(hash) {
  return hash.length == 66 && hash.startsWith('0x');
}

function shortenHash(hash) {
  return hash.substring(0, 6) + '...' + hash.substring(hash.length-4, hash.length);
}

function shortenAddress(address, format) {
  let result = address;
  if(address && address.length == 42 && address.startsWith('0x')) {
    result = address.substring(0, 6) + '...' + address.substring(42-4, 42);

    if(format) {
      return '<a href="'+ETHERSCAN_URL[networkId]+'/address/'+address+'" target="_blank">' + result + '</a>';
    }
  }
  return result;
}

function txHashUrl(txHash) {
  return ETHERSCAN_URL[networkId]+'/tx/'+txHash;
}

function formatAddress(address) {
  return '<a href="'+ETHERSCAN_URL[networkId]+'/address/'+address+'" target="_blank">' + address + '</a>';
}

function loadContract(contractABI, address) {
  let instance = new web3Eth.Contract(contractABI.abi, address);
  let name = contractABI.contractName;
  contracts[name] = {
    name: name,
    address: address,
    instance: instance
  };
  console.log(contracts[name]);
  return contracts[name];
}

function contract(name) {
  return contracts[name];
}

function loadABI(abiNames) {
  let loadingABIPromises = [];
  abiNames.forEach(item => {
    loadingABIPromises.push(
      import('../assets/abi/'+item+'.json').then((imported) => {
        abi[item] = imported;
      }).catch((error) => {
        console.error(item + ' is invalid!');
        console.error(error);
      })
    );
  });
  return Promise.all(loadingABIPromises);
}

function newContract(contractABI, parameters) {

  let hashPromiseCallbacks = {};
  let hashPromise = new Promise((resolve, reject) => {
    hashPromiseCallbacks.resolve = resolve;
    hashPromiseCallbacks.reject = reject;
  });

  let minedPromiseCallbacks = {};
  let minedPromise = new Promise((resolve, reject) => {
    minedPromiseCallbacks.resolve = resolve;
    minedPromiseCallbacks.reject = reject;
  });

  let contract = new web3Eth.Contract(contractABI.abi);
  let args = [].concat(parameters);
  console.log(parameters);
  const data = contract.deploy({ data: contractABI.bytecode, arguments: args }).encodeABI();

  window.ethereum.request({ method: 'eth_sendTransaction',
    params: [{ from: currentAccount.address, to: undefined, data: data, value: '0x0'}]
  }).then((data) => {
    hashPromiseCallbacks.resolve(data);
    waitForReceipt(data).then((receipt) => {
      console.log(receipt);
      minedPromiseCallbacks.resolve(receipt);
    }).catch((error) => {
      minedPromiseCallbacks.reject(error);
    });
  }).catch((error) => {
    console.error(error);
    hashPromiseCallbacks.reject(error);
    minedPromiseCallbacks.reject(error);
  });

  return {
    hashPromise: hashPromise,
    minedPromise: minedPromise
  };
}

function callMethod(contract, method, parameters) {
  let args = [].concat(parameters);

  let hashPromiseCallbacks = {};
  let hashPromise = new Promise((resolve, reject) => {
    hashPromiseCallbacks.resolve = resolve;
    hashPromiseCallbacks.reject = reject;
  });

  let minedPromiseCallbacks = {};
  let minedPromise = new Promise((resolve, reject) => {
    minedPromiseCallbacks.resolve = resolve;
    minedPromiseCallbacks.reject = reject;
  });

  const data = method.apply(this, args).encodeABI();
  window.ethereum.request({ method: 'eth_sendTransaction',
    params: [{ from: currentAccount.address, to: contract.address, data: data, value: '0x0'}]
  }).then((data) => {
    console.log(data);
    hashPromiseCallbacks.resolve(data);
    waitForReceipt(data).then((receipt) => {
      console.log(receipt);
      minedPromiseCallbacks.resolve(receipt);
    }).catch((error) => {
      minedPromiseCallbacks.reject(error);
    });
  }).catch((error) => {
    console.error(error);
    hashPromiseCallbacks.reject(error);
    minedPromiseCallbacks.reject(error);
  });

  return {
    hashPromise: hashPromise,
    minedPromise: minedPromise
  };
}

function waitForReceipt(hash) {
  return new Promise(function(resolve, reject) {
    let interval = 1000;
    let timeout = 200000;
    console.log('checking for tx '+hash);
    let timer = window.setInterval(() => {
      web3Eth.getTransactionReceipt(hash, function (error, receipt) {
        if (error) {
          console.error(error);
          reject(error);
        }
        if (receipt !== null) {
          console.log('Receipt:');
          console.log(receipt);
          console.log('tx mined !');
          // Transaction went through
          window.clearInterval(timer);
          resolve(receipt);
        } else {
          timeout -= interval;
          if(timeout <= 0) {
            console.log('Timeout reached!');
            // timeout
            window.clearInterval(timer);
            reject(hash);
          }
        }
      });
    }, interval);
  });
}

let metamaskLoading = new Promise((resolve, reject) => {
    if (window.ethereum) {
      web3Eth = new Web3Eth(window.ethereum);

      return Promise.all([
        window.ethereum.request({ method: 'eth_chainId' }).then((chainId) => {
          networkId = chainId;
          if(!ETHERSCAN_URL[networkId]) {
            console.warn('Unkown networkId '+networkId);
          }
        }).catch((error) => { console.error(error); }),

        window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
          currentAccount.address = accounts[0];
          console.log(accounts);
          web3Eth.getBalance(accounts[0], (error, data) => {
            if (data) {
              currentAccount.balance = data;
              resolve(currentAccount);
            } else {
              console.error(error);
              reject(error);
            }
          }).catch((error) => { console.error(error); })
        }).catch((error) => { console.error(error); }),
      ]);
    }
    else {
      console.error('You need to install MetaMask!');
      reject();
    }
});

function reloadOnMetamaskChanges() {
  if(window.ethereum) {
    window.ethereum.on('accountsChanged', function (accounts) {
      // Time to reload your interface with accounts[0]!
      location.reload()
    });
    
    window.ethereum.on('chainChanged', function (netId) {
      // Time to reload your interface with netId
      location.reload()
    });  
  }
}

function getNetworkId() {
  return networkId;
}

export { getNetworkId, lookupName, lookupAddress, isAddressValid, isHashValid, shortenHash, shortenAddress, txHashUrl, formatAddress, loadContract, abi, loadABI, contract, metamaskLoading, reloadOnMetamaskChanges, newContract, callMethod, waitForReceipt };
