import { newContract, metamaskLoading, callMethod, loadABI, abi, loadContract } from "./ethereum.js";
import Web3Utils from 'web3-utils';

const ABI_NAMES = [ "IERC20", "Tokensale", "ITokenFactory" ];
let owner;
let deployment;
let contracts = {};

function deployToken() {
  let delegateId = 1;

  let supplies = deployment.parameters.supplies.map((supply) => 
    supply + "".padEnd(deployment.parameters.decimals, "0"));
  let promises = callMethod(contracts.tokenFactory, contracts.tokenFactory.instance.methods.deployToken, [
    delegateId,
    deployment.parameters.name,
    deployment.parameters.symbol,
    deployment.parameters.decimals,
    deployment.parameters.lockEnd,
    deployment.parameters.vaultERC20s,
    supplies,
    [ owner.address ]
  ]);
  
  promises.minedPromise =
    promises.minedPromise.then((receipt) => {
      console.log(receipt);
      if(receipt && receipt.logs.length > 0
          && receipt.logs[0].topics.length == 2) {
        let address = "0x" + receipt.logs[0].topics[1].substr(26);
        contracts.token = loadContract(abi.IERC20, address);
        console.log(contracts.token);
        return;
      } else {
        throw "Unable to mined the token";
      }
    });

  return promises;
}

function createPrivateTokensale() {
  let price = deployment.parameters.tokenPrice;
  let priceUnit = deployment.parameters.priceUnit
    + "".padEnd(deployment.parameters.decimals, "0");
  let currency = Web3Utils.fromAscii(deployment.parameters.tokenPriceCurrency);

  let promises = newContract(abi.Tokensale, [
    contracts.token.address,
    contracts.tokenFactory.address,
    deployment.parameters.ethVault,
    price,
    priceUnit,
    currency,
    deployment.parameters.userRegistry,
    deployment.parameters.ratesProvider,
    deployment.parameters.privateSale.from,
    deployment.parameters.privateSale.to
  ]);
  promises.minedPromise =
    promises.minedPromise.then((receipt) => {
      console.log(receipt);
      contracts.privateSale = loadContract(abi.Tokensale, receipt.contractAddress);
    });
  return promises;
}

function createPublicTokensale() {
  let price = deployment.parameters.tokenPrice;
  let priceUnit = deployment.parameters.priceUnit
    + "".padEnd(deployment.parameters.decimals, "0");
  let currency = Web3Utils.fromAscii(deployment.parameters.tokenPriceCurrency);

  let promises = newContract(abi.Tokensale, [
    contracts.token.address,
    contracts.tokenFactory.address,
    deployment.parameters.ethVault,
    price,
    priceUnit,
    currency,
    deployment.parameters.userRegistry,
    deployment.parameters.ratesProvider,
    deployment.parameters.publicSale.from,
    deployment.parameters.publicSale.to
  ]);
  promises.minedPromise =
    promises.minedPromise.then((receipt) => {
      console.log(receipt);
      contracts.publicSale = loadContract(abi.Tokensale, receipt.contractAddress);
    });
  return promises;
}

function definePrivateSaleBonuses() {
  return callMethod(contracts.privateSale, contracts.privateSale.instance.methods.defineBonuses, [
    deployment.parameters.privateSale.bonusMode,
    [ deployment.parameters.privateSale.bonus ],
    [ deployment.parameters.privateSale.bonusUntil ],
  ]);
}

function definePublicSaleBonuses() {
  return callMethod(contracts.publicSale, contracts.publicSale.instance.methods.defineBonuses, [
    deployment.parameters.publicSale.bonusMode,
    [ deployment.parameters.publicSale.bonus ],
    [ deployment.parameters.publicSale.bonusUntil ],
  ]);
}

function configureTokensales() {
  let privateSaleSupply = deployment.parameters.privateSale.supply
    + "".padEnd(deployment.parameters.decimals, "0");
  let publicSaleSupply = deployment.parameters.publicSale.supply
    + "".padEnd(deployment.parameters.decimals, "0");

  return callMethod(contracts.tokenFactory, contracts.tokenFactory.instance.methods.configureTokensales, [
    contracts.token.address,
    [ contracts.privateSale.address, contracts.publicSale.address ],
    [ privateSaleSupply, publicSaleSupply ]
  ]);
}

function dummyStep() {
  return {
    minedPromise: new Promise((resolve, reject) => {
      setTimeout(resolve({address: "0x333333333"}), 100);
    }),
    hashPromise: new Promise((resolve, reject) => {
      setTimeout(resolve("0x213133333"), 500);
    })
  };
}

const fullSteps = [{
  title: "Deploy Token",
  method: deployToken
}, {
  title: "Deploy Private Sale",
  method: createPrivateTokensale,
  condition: (params) => {
    return (params.privateSale)
  }
}, {
  title: "Define Private Sale Bonuses",
  method: definePrivateSaleBonuses,
  condition: (params) => {
    return (params.privateSale)
  }
}, {
  title: "Deploy Public Sale",
  method: createPublicTokensale,
  condition: (params) => {
    return (params.publicSale)
  }
}, {
  title: "Define Public Sale Bonuses",
  method: definePublicSaleBonuses,
  condition: (params) => {
    return (params.publicSale)
  }
}, {
  title: "Configure Tokensales",
  method: configureTokensales,
  condition: (params) => {
    return (params.privateSale || params.publicSale)
  }
}];

export default function DeployToken() {
  const status = {
    ready: false,
    step: 0,
    success: true,
    errors: []
  };

  owner = {
    address: undefined,
    balance: 0
  }

  const parameters = {  }

  const steps = [];

  const evalSteps = () => {
    console.log(deployment.parameters);
    fullSteps.forEach((step) => {
      if (!step.condition || step.condition(deployment.parameters)) {
        steps.push(step);
      }
    });

    let tokenFactory = loadContract(abi.ITokenFactory, deployment.parameters.tokenFactory);
    contracts.tokenFactory = tokenFactory;

    contracts.addresses = {
      core: deployment.parameters.core,
      userRegistry: deployment.parameters.userRegistry,
      ratesProvider: deployment.parameters.ratesProvider
    };
  }

  let readyPromise = Promise.all([
    metamaskLoading.then((result) => {
      owner.address = result.address;
      owner.balance = result.balance;
      status.ready = true;
    }),
    loadABI(ABI_NAMES)
  ]);

  deployment = {
    owner: owner,
    next: function() {
      let promises = steps[status.step].method();
      promises.minedPromise = 
        promises.minedPromise.then(() => {
          status.step++;
          console.log(status);
        });
      promises.hashPromise =
        promises.hashPromise.then((hash) => {
          deployment.hashes.push(hash);
          return hash;
        });
      return promises;
    },
    hashes: [],
    evalSteps: evalSteps,
    steps: steps,
    status: status,
    parameters: parameters,
    contracts: contracts,
    readyPromise: readyPromise
  };
  return deployment;
}