import "./styles/index.css";
import DeployToken from "./modules/deploy-token.js";
import { getNetworkId, shortenAddress, formatAddress, isAddressValid, txHashUrl, metamaskLoading, reloadOnMetamaskChanges } from "./modules/ethereum";

var deployToken;
var oraclesAddresses = {
  '0x1': {
    "Altcoinomy": {
      userRegistry: "0xeeb655488f20B23aBCa2e2BD232dC4607B62C843",
      ratesProvider: "0x0C95bcD7E426f7228b87A43ff741C5c268433fa0",
      core: "0xE9e10a5dE4a4920D9A5fD6F0C2F205eb575fD7D6",
      tokenFactory: "0x1e61C10F3afd2eC2f4b38e9f1346A6861e1d586d"
    },
    "Demo": {
      userRegistry: "0xE135a2903A64657eeA414962b05fa8bF6590098F",
      ratesProvider: "0x72774f837888734ADde4f4B455cF36B76D2eeA27",
      core: "0x93A20D8EbA6997ABB53aE94bd01727C8e74c8734",
      tokenFactory: "0x877D884bBe88354A2C210deDe22F60f0A0260254"
    }
  },
  '0x3': { // 3 is the ropsten network
    "Altcoinomy": {
      userRegistry: "0x458D3C60C4D1350aB6238b272b220219659694D6",
      ratesProvider: "0x480A6e9db2913AbA31E9655df2866603B0082755",
      core: "0x14cA4d241D6061775cFe82A24e90b2b7Db822aDb",
      tokenFactory: "0x658B842E220EF14b770037d0d27759bF282C7642"
    },
    "Demo": {
      userRegistry: "0x909469c474cA8b332Fd6EFdd9d201636cFa09301",
      ratesProvider: "0xfe52F11D7ffA529475b695159a1d4d43157bedfF",
      core: "0xF0C57e93Bd88CF45697a29B5f184768783531966",
      tokenFactory: "0x544280C0B71C1296Fd556F7Fa73bb856edB5997D"
    }
  },
  '0x4': { },
  '0x42': { },  // No Oracle known
};
const CURRENCIES = [
  { name: "ETH", decimals: 18 },
  { name: "BTC", decimals: 8 },
  { name: "EOS", decimals: 4 },
  { name: "GBP", decimals: 2 },
  { name: "USD", decimals: 2 },
  { name: "CHF", decimals: 2 },
  { name: "EUR", decimals: 2 },
  { name: "CNY", decimals: 2 },
  { name: "JPY", decimals: 2 },
  { name: "CAD", decimals: 2 },
  { name: "AUD", decimals: 2 }
];


const formatDate = function(timestamp) {
  return new Date(timestamp).toLocaleDateString("en",  {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

function previousSetupStep() {
  var steps = document.getElementsByClassName("step");
  let active = -1;
  for (let i=0; i < steps.length ; i++) {
    if (steps.item(i).classList.contains("active")) {
      active = i;
    }
  }

  if(active > 0) {
    steps.item(active).classList.remove("active");

    var beforeCard = document.getElementById("step"+active);
    beforeCard.style.display="none";
    var currentCard = document.getElementById("step"+(active-1));
    currentCard.style.display="block";
  }

  if(active == steps.length-2) {
    var next = document.getElementById("next");
    next.style.display = "inline-block";
    var deploy = document.getElementById("deploy");
    deploy.style.display = "none";
  }

  return false;
}

function nextSetupStep() {
  var steps = document.getElementsByClassName("step");
  let active = -1;
  for (let i=0; i < steps.length ; i++) {
    if (steps.item(i).classList.contains("active")) {
      active = i;
    }
  }

  if(active < steps.length-1) {
    steps.item(active+1).classList.add("active");

    let beforeCard = document.getElementById("step"+active);
    beforeCard.style.display="none";

    let currentCard = document.getElementById("step"+(active+1));
    currentCard.style.display="block";
  }

  if(active+1 == steps.length-2) {
    var next = document.getElementById("next");
    next.style.display = "none";
    var deploy = document.getElementById("deploy");
    deploy.style.display = "inline-block";
  }
  if(active+1 == steps.length-1) {
    var navigation = document.getElementById("navigation");
    navigation.style.display="none";
    buildList();
    deployContracts();
  }

  if (active == 2) {
    buildSummary();
  }
  return false;
}

function checkInput(checkbox, inputId) {
  var input = document.getElementById(inputId);

  input.disabled = !checkbox.checked;
}

function buildSummary() {
  var fields = [];
  var parameters = deployToken.parameters;

  var field = { name: "Name" };
  let nameValue = document.getElementById("name").value;
  let symbolValue =  document.getElementById("symbol").value;
  
  if (nameValue.length == 0 || symbolValue.length == 0) {
    field.error = "Name and Symbol must be defined!";
  } else {
    symbolValue = symbolValue.toUpperCase()
    field.value = nameValue + " (" + symbolValue + ")";
    parameters.name = nameValue;
    parameters.symbol = symbolValue;
  }
  fields.push(field);

  let tokenType = document.getElementById("tokenTypeSelect").value;
  fields.push({ name: "Token Type", value: tokenType + " (ERC20, ERC1592)" });
  parameters.tokenType = tokenType;

  let complianceCoreValue = document.getElementById("complianceCore").value;
  let coreOracle = oraclesAddresses[getNetworkId()][complianceCoreValue];

  var field = { name: "Compliance core", value: complianceCoreValue };
  if(coreOracle && coreOracle.core && coreOracle.tokenFactory && coreOracle.userRegistry) {
    parameters.core = coreOracle.core;
    parameters.tokenFactory = coreOracle.tokenFactory;
    parameters.userRegistry = coreOracle.userRegistry

    field.value += " (" + shortenAddress(coreOracle.core, true) + ")";
  } else {
    field.error = "Invalid Core selected!";
  }

  fields.push(field);
  var field = { name: "Decimal", value: "18" };
  switch(tokenType) {
    case "Utility":
      break;
    case "Payment":
      break;
    case "Security":
      break;
    case "Equity":
      field.value = 0;
      break;
    case "Bond":
      break;
    default:
      console.error("'Invalid token type value: '"+tokenType+"'");
  }
  fields.push(field);
  parameters.decimals = field.value;

  var field = { name: "Supply" };
  let supplyValue = document.getElementById("supply").value;
  supplyValue = parseSupply(supplyValue);

  if(supplyValue.length == 0 || isNaN(supplyValue)) {
    field.error = "Must be a valid number!";
  } else {
    field.value = formatSupply(supplyValue) + " " + symbolValue;
  }
  fields.push(field);

  let tokenPrice = document.getElementById("tokenPrice").value;
  let tokenPriceCurrency = document.getElementById("tokenPriceCurrency").value;
  let priceUnit = document.getElementById("priceUnit").value;

  var field = { name: "Token Price" };
  let tokenPriceDecimals;
  CURRENCIES.forEach((currency) => {
    if(currency.name == tokenPriceCurrency) {
      tokenPriceDecimals = currency.decimals;
    }
  })

  // Round price to currency decimals
  tokenPrice = Math.floor(tokenPrice*(10**tokenPriceDecimals));
  if(tokenPrice.length == 0 || isNaN(tokenPrice) || tokenPrice == 0) {
    field.error = "Must be a positive number!";
  } else {
    tokenPrice = tokenPrice;
    parameters.tokenPrice = tokenPrice;
    field.value = tokenPrice/ (10**tokenPriceDecimals) + " " + tokenPriceCurrency;
    parameters.priceUnit = priceUnit;
    if (priceUnit != 1) {
      field.value += " for " + priceUnit + " " + symbolValue;
    }
  }
  fields.push(field);
  parameters.tokenPriceCurrency = tokenPriceCurrency;

  var field = { name: "Owner", value: shortenAddress(deployToken.owner.address, true) };
  fields.push(field);

  var field = { name: "ETH Vault" };
  var ethVaultValue = document.getElementById("ethVault").value;
  ethVaultValue = !ethVaultValue.startsWith("0x") ? "0x" + ethVaultValue : ethVaultValue;
  if(!isAddressValid(ethVaultValue)) {
    field.error = "Must be a valid address!";
  } else {
    field.value = shortenAddress(ethVaultValue, true);
    parameters.ethVault = ethVaultValue;
  }
  fields.push(field);

  let ratesOracleValue = document.getElementById("ratesOracle").value;
  var field = { name: "Rates Provider" };
  if(ratesOracleValue) {
    let oracle = oraclesAddresses[getNetworkId()][ratesOracleValue];

    parameters.ratesProvider = "";
    field.value = ratesOracleValue;
    if(oracle && oracle.ratesProvider) {
      parameters.ratesProvider = oracle.ratesProvider;
      field.value += " (" + shortenAddress(oracle.ratesProvider, true) + ")";
    } else {
      field.error = "Name must be defined!";
    }
    fields.push(field);
  }

  const evalSupply = function(inputName) {
    let supply = parseSupply(document.getElementById(inputName).value);
    let result = formatSupply(supply) + " " + symbolValue;
    return { field: result, parameter: supply };
  }

  parameters.vaultERC20s = [];
  parameters.supplies = [];

  var reservedSupply = evalSupply("reservedAllocationSupply");
  fields.push({ name: "Reserved", value: reservedSupply.field });
  parameters.reservedSupply = reservedSupply.parameter;
  if(parameters.reservedSupply > 0) {
    parameters.vaultERC20s.push(deployToken.owner.address);
    parameters.supplies.push(parameters.reservedSupply);
  }

  var nonAllocatedSupply = Number(supplyValue) - reservedSupply.parameter;
  if(nonAllocatedSupply > 0) {
    parameters.vaultERC20s.push(parameters.tokenFactory);
    parameters.supplies.push(nonAllocatedSupply);
  }

  const toTimestamp = function(dateString) {
    if(typeof dateString == "string") {
      var dateParts = dateString.split("/");
      if(dateParts.length == 3) {
        return new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1])).getTime();
      }
    }
  }

  const saleOpening = function(inputName, fieldName) {
    var field = { name: fieldName };
    var parameters = {};
    let fromDateValue = document.getElementById(inputName + "From").value;
    let toDateValue = document.getElementById(inputName + "To").value;

    var fromDatetime = toTimestamp(fromDateValue);
    var toDatetime = toTimestamp(toDateValue);

    let now = new Date().getTime()
    if (!fromDatetime || !toDatetime || fromDatetime < now || fromDatetime >= toDatetime) {
      field.error = "Must be a valid interval in the future!";
    } else {
      let halfDay = 43200; // 12h00
      field.value = fromDateValue + " to " + toDateValue;
      parameters = { from: (fromDatetime / 1000 + halfDay), to: (toDatetime / 1000 + halfDay) };
    }
    return { field: field, parameters: parameters };
  }

  var privateSaleSupply = evalSupply("privateSaleAllocationSupply");
  if(privateSaleSupply.parameter) {
    fields.push({ name: "Private Sale Supply", value: privateSaleSupply.field });
    var opening =
      saleOpening("privateSale", "Private Sale Openings");
    fields.push(opening.field);
    let privateSaleBonus = document.getElementById("privateSaleBonus").value;
    let privateSaleBonusModeEarly = document.getElementById("privateSaleBonusModeEarly").checked;
    let privateSaleBonusModeFirst = document.getElementById("privateSaleBonusModeFirst").checked;

    let bonusMode = 0;
    let bonusUntil = 0;
    let bonusUntilField = { name: "Private Sale Bonus", value: "N/A" };
    if (privateSaleBonusModeEarly) {
      bonusMode = 1;
      bonusUntil = toTimestamp(document.getElementById("privateSaleBonusEarlyUntil").value);
      bonusUntilField.value = "+" + privateSaleBonus + "% until " + formatDate(bonusUntil);
    }
    if (privateSaleBonusModeFirst) {
      bonusMode = 2;
      bonusUntil = parseSupply(document.getElementById("privateSaleBonusFirstUntil").value);
      bonusUntilField.value = "+" + privateSaleBonus + "% for the first " + formatSupply(bonusUntil) + " " + symbolValue;
    }  

    fields.push(bonusUntilField);
    parameters.privateSale = {
      supply: privateSaleSupply.parameter,
      from: opening.parameters.from,
      to: opening.parameters.to,
      bonus: privateSaleBonus,
      bonusMode: bonusMode,
      bonusUntil: bonusUntil
    };

    nonAllocatedSupply -= privateSaleSupply.parameter;
  } else {
    fields.push({ name: "Private Sale", value: "No tokens to distribute"})
  };

  var publicSaleSupply = evalSupply("publicSaleAllocationSupply");
  if(publicSaleSupply.parameter) {
    fields.push({ name: "Public Sale Supply", value: publicSaleSupply.field });
    var opening =
      saleOpening("publicSale", "Public Sale Openings");
    fields.push(opening.field);

    let publicSaleBonus = document.getElementById("publicSaleBonus").value;
    let publicSaleBonusModeEarly = document.getElementById("publicSaleBonusModeEarly").checked;
    let publicSaleBonusModeFirst = document.getElementById("publicSaleBonusModeFirst").checked;

    let bonusMode = 0;
    let bonusUntil = 0;
    let bonusUntilField = { name: "Public Sale Bonus", value: "N/A" };
    if(publicSaleBonusModeEarly) {
      bonusMode = 1;
      bonusUntil = toTimestamp(document.getElementById("publicSaleBonusEarlyUntil").value);
      bonusUntilField.value = "+" + publicSaleBonus + "% until " + formatDate(bonusUntil);
    }
    if(publicSaleBonusModeFirst) {
      bonusMode = 2;
      bonusUntil = parseSupply(document.getElementById("publicSaleBonusFirstUntil").value);
      bonusUntilField.value = "+" + publicSaleBonus + "% for the first " + formatSupply(bonusUntil) + " " + symbolValue;
    }
    
    if (!opening.field.error) {
      parameters.publicSale = {
        supply: publicSaleSupply.parameter,
        from: opening.parameters.from,
        to: opening.parameters.to,
        bonus: publicSaleBonus,
        bonusMode: bonusMode,
        bonusUntil: bonusUntil
      }
    }
    fields.push(bonusUntilField);
    nonAllocatedSupply -= publicSaleSupply.parameter;
  } else {
    fields.push({ name: "Public Sale", value: "No tokens to distribute"})
  };

  fields.push({ name: "Non Allocated", value: formatSupply(nonAllocatedSupply) + " " + symbolValue });
  parameters.nonAllocatedSupply = nonAllocatedSupply;

  let vestingPeriodMonths = Number(document.getElementById("vestingPeriod").value);
  var field = { name: "Vesting Period", value: "N/A" };

  if (parameters.privateSale && parameters.publicSale || Number(vestingPeriodMonths) > 0) {
    let toDate = new Date();
    if(parameters.privateSale && parameters.publicSale) {
      let to = (parameters.privateSale.to > parameters.publicSale.to) ?
      parameters.privateSale.to : parameters.publicSale.to;
      toDate = new Date(to * 1000);
    }

    let lockMonthDelta = toDate.getMonth() + Number(vestingPeriodMonths);
    let vestingEnd = toDate.setMonth(lockMonthDelta);
    parameters.lockEnd = vestingEnd / 1000;
    
    field.value = "Locked until " + formatDate(vestingEnd);
  } else {
    field.value = "No lock defined";
  }
  fields.push(field);

  var body = document.getElementById("summary-body");
  var hasErrors = false;
  body.innerHTML = "<div class='container'>";
  fields.forEach((field) => {
    var row = "<div class='row'>"

    if(field.error) {
      row += "  <div class='col col-4 font-weight-bold text-danger'>" + field.name + "</div>";
      row += "  <div class='col col-8 text-danger'>"+field.error + "</div>";
      hasErrors = true;
    } else {
      row += "  <div class='col col-4 font-weight-bold'>" + field.name + "</div>";
      row += "  <div class='col col-8'>"+field.value + "</div>";    }
    row += "</div>";
    body.innerHTML += row;
  });
  body.innerHTML += "</div>";

  if(!hasErrors) {
    var errorMessage = document.getElementById("error-message");
    errorMessage.classList.add("d-none");

    var deploy = document.getElementById("deploy");
    deploy.removeAttribute("disabled");
  }
}

function buildEndReport() {
  let contracts = deployToken.contracts;
  let fields = [
    { name: "Token", address: (contracts.token) ? contracts.token.address: "-" },
    { name: "Core", address: contracts.addresses.core },
    { name: "User registry", address: contracts.addresses.userRegistry },
    { name: "Rates provider", address: contracts.addresses.ratesProvider },
    { name: "Token Factory", address: (contracts.tokenFactory) ? contracts.tokenFactory.address: "-" },
    { name: "Private Sale", address: (contracts.privateSale) ? contracts.privateSale.address: "-" },
    { name: "Public Sale", address: (contracts.publicSale) ? contracts.publicSale.address: "-" },
  ];

  var body = document.getElementById("endreport-body");
  body.innerHTML = "<div class='container'>";
  fields.forEach((field) => {
    var row = "<div class='row'>";
    row += "  <div class='col col-4 font-weight-bold'>" + field.name + "</div>";
    row += "  <div class='col col-8'>"+ formatAddress(field.address, true) + "</div>";
    row += "</div>";
    body.innerHTML += row;
  });
  body.innerHTML += "</div>";
}

let buildList = function() {
  deployToken.evalSteps();
  var itemsList = document.getElementById("itemsList");

  deployToken.steps.forEach((step, i) => {
    var li = document.createElement("li");
    li.setAttribute("id", "item" + i);
    li.classList.add("list-group-item");

    var row = document.createElement("div");
    row.classList.add("row");
    var col = document.createElement("div");
    col.classList.add("col", "col-10");
    col.textContent = (i+1)+ '. ' + step.title;
    row.appendChild(col);
    li.appendChild(row);
    itemsList.appendChild(li);
  });

  updateList(0);
}

let updateList = function(i) {
  if(i > 0) {
    let itemName = "item" + (i-1);
    var item0 = document.getElementById(itemName);
    item0.classList.remove("list-group-item-warning");
    item0.classList.add("list-group-item-success");
  }

  if(deployToken.status.step < deployToken.steps.length) {
    var item1 = document.getElementById("item"+i);
    item1.classList.add("list-group-item-warning");

    var status = document.createElement("div");
    status.classList.add("col","col-2","text-right");
    item1.children[0].appendChild(status);

    var hashLink = document.createElement("a");
    hashLink.setAttribute("id", item1.id + "-a");
    hashLink.classList.add("fas", "fa-external-link-alt", "d-none");
    status.appendChild(hashLink);

    var deployStatus = document.getElementById("deployStatus");
    status.append(deployStatus);
  } else {
    var spinner = document.getElementById("deployStatus");
    spinner.classList.add("d-none");
  }
}

function deployContracts() {
  var deployment = document.getElementById("deployment");
  deployment.style.display = "block";

  let updateHash = function (i, hash) {
    var a = document.getElementById("item"+i+"-a");
    a.setAttribute("href", txHashUrl(hash));
    a.setAttribute("target", "_blank");
    a.classList.remove("d-none");
  }

  let updateError = function(i) {
    var item0 = document.getElementById("item"+(i));
    item0.classList.remove("list-group-item-warning");
    item0.classList.add("list-group-item-danger");

    var spinner = document.getElementById("deploySpinner");
    spinner.classList.add("d-none");
    var redo = document.getElementById("redo");
    redo.style.display="inline-block";
  };

  let status = deployToken.status;
  let promise = Promise.resolve();
  for(var i=status.step; i < deployToken.steps.length; i++) {
    let index = i;
    promise = promise.then(() => {
      let promises = deployToken.next();
      return promises.hashPromise.then((hash) => {
        updateHash(index, hash);
      }).then(() => promises.minedPromise);
    }).then(() => {
      updateList(status.step);
    });
  }

  promise.catch((error) => {
    console.error(error);
    updateError(status.step);
  }).then(() => {
    if(deployToken.status.step == deployToken.steps.length) {
      var spinner = document.getElementById("deploySpinner");
      spinner.classList.add("d-none");

      buildEndReport();
      var endreport = document.getElementById("endreport");
      endreport.style.display="block";
    }
  });

  return false;
}

function redo() {
  var step = deployToken.status.step;

  var item0 = document.getElementById("item"+(step));
  item0.classList.remove("list-group-item-danger");
  item0.classList.add("list-group-item-warning");
  var spinner = document.getElementById("deploySpinner");
  spinner.classList.remove("d-none");
  var redo = document.getElementById("redo");
  redo.style.display="none";

  deployContracts();
}

function setupTokenTypeSelect() {
  var selectElement = document.getElementById("tokenTypeSelect");

  const showOnlyClass = function(className) {
    var elements = document.getElementsByClassName('tokenDescription');
    for(var i = 0; i < elements.length; i++) {
      var element = elements.item(i);
      if(element.classList.value.indexOf(className) != -1) {
        element.style.display="";
      } else {
        element.style.display="none";
      }
    };
  }

  selectElement.addEventListener('change', (event) => {
    showOnlyClass(event.target.value + 'Token');
  });
  showOnlyClass(selectElement.value + 'Token');
}

function setupAllocationSliders() {
  var sliders = [
    new Slider("#reservedAllocation", {
      min: 0,
      max: 100,
      value: 20,
      step: 1,
    }),
    new Slider("#privateSaleAllocation", {
      min: 0,
      max: 100,
      value: 45,
      step: 1,
    }),
    new Slider("#publicSaleAllocation", {
      min: 0,
      max: 100,
      value: 20,
      step: 1,
    }),
    new Slider("#nonAllocated", {
      min: 0,
      max: 100,
      value: 15,
      step: 1,
      enabled: false,
      handle: "custom"
    })
  ];

  sliders.forEach((slider, i) => {
    if(i < 3) {
      slider.on("change", () => { updateAllocations(sliders,i) });
      document.getElementById(slider.element.id + "Supply").addEventListener("change", () => {
        updateSupplies(sliders,i);
      });
      updateAllocations(sliders,i);
    }
  });
  document.getElementById("supply").addEventListener("change", (event) => {
    updateSupplies(sliders, -1);
  })
}

function formatSupply(value) {
  let valueStr = String(Math.round(value));
  let decimalIndex = valueStr.indexOf(".");
  decimalIndex = (decimalIndex == -1) ? valueStr.length : decimalIndex;
  let integerPart = valueStr.substring(0, decimalIndex);
  let decimalPart = valueStr.substring(decimalIndex);

  let formattedValue = decimalPart;
  let i = integerPart.length;
  while(i > 0) {
    let j = (i > 3) ? i-3 : 0;
    if (i == integerPart.length) {
      formattedValue = integerPart.substring(j, i) + formattedValue;
    } else {
      formattedValue = integerPart.substring(j, i) + "'" + formattedValue;
    }
    i = j;
  }
  return formattedValue;
}

function parseSupply(value) {
  let parsedValue = value.replace(new RegExp("'", "g"), "");
  return Number(parsedValue);
}

function updateAllocations(sliders,i) {
  let sum = 0;
  sliders.forEach((slider, i) => {
    sum += slider.getValue();
  });
  let available = sliders[3].getValue();
  if(sum > 100) {
    if((sum - 100) <= available) {
      sliders[3].setValue(available - (sum - 100));
    } else {
      sliders[3].setValue(0);
      sliders[i].setValue(sliders[i].getValue() - (sum - 100 - available));
    }
  }
  if(sum < 100) {
    sliders[3].setValue(sliders[3].getValue() + (100 - sum));
  }
  let supply = parseSupply(document.getElementById("supply").value);
  let sliderSupply = supply * (sliders[i].getValue() / 100);
  document.getElementById(sliders[i].element.id + "Supply").value = formatSupply(sliderSupply);
  let reservedSupply = supply * (sliders[3].getValue() / 100);
  document.getElementById(sliders[3].element.id + "Supply").value = formatSupply(reservedSupply);

  sliders.forEach((slider) => {
    if(document.getElementById(slider.element.id + "Display")) {
      document.getElementById(slider.element.id + "Display").innerHTML =
      Math.floor(slider.getValue()) + "%";
    }
  });
}

function updateSupplies(sliders, i) {
  let supply = parseSupply(document.getElementById("supply").value);
  if (i >= 0) {
    let sum = 0;
    sliders.forEach((slider, i) => {
      sum += parseSupply(document.getElementById(sliders[i].element.id + "Supply").value);
    });
    if(sum > supply) {
      let available = parseSupply(document.getElementById(sliders[3].element.id + "Supply").value);
      if((sum - supply) <= available) {
        document.getElementById(sliders[3].element.id + "Supply").value =
        formatSupply(available - (supply - sum));
      } else {
        document.getElementById(sliders[3].element.id + "Supply").value = 0;
        let value = parseSupply(document.getElementById(sliders[i].element.id + "Supply").value);
        document.getElementById(sliders[i].element.id + "Supply").value =
        formatSupply(value - (sum - supply - available));
      }
    }
    if(sum < supply) {
      let nonAllocated = parseSupply(document.getElementById(sliders[3].element.id + "Supply").value);
      document.getElementById(sliders[3].element.id + "Supply").value =
        formatSupply(nonAllocated + (supply - sum));
    }

    sliders.forEach((slider, i) => {
      let parsedValue = parseSupply(document.getElementById(sliders[i].element.id + "Supply").value);
      document.getElementById(sliders[i].element.id + "Supply").value = formatSupply(parsedValue);
      let percent = 100 * parsedValue / supply;
      slider.setValue(percent);
      if(document.getElementById(slider.element.id + "Display")) {
        document.getElementById(slider.element.id + "Display").innerHTML =
        Math.floor(slider.getValue()) + "%";
      }
    });
  } else {
    sliders.forEach((slider) => {
      if(document.getElementById(slider.element.id + "Supply")) {
        document.getElementById(slider.element.id + "Supply").value =
          formatSupply(supply * slider.getValue() / 100);
      }
    });
    document.getElementById("supply").value = formatSupply(supply);
  }
}

function updateSymbol() {
  let symbol = document.getElementById("symbol").value;
  let elements = document.getElementsByClassName("amount-symbol");

  for (let i=0; i < elements.length ; i++) {
    let item = elements.item(i);
    item.innerHTML = symbol;
  }
}

function setupTokensaleDefaultDate() {
  let begin = new Date();
  begin.setMonth(begin.getMonth() + 1);
  begin.setDate(1);
  let privateSaleFrom = formatDate(begin);

  let midSale = new Date(begin);
  midSale.setDate(15);
  let privateSaleBonusUntil = formatDate(midSale);

  let end = new Date(begin);
  end.setMonth(begin.getMonth() + 1);
  end.setDate(0);
  let privateSaleTo = formatDate(end);
  
  begin.setMonth(begin.getMonth() + 1);
  let publicSaleFrom = formatDate(begin);

  midSale.setMonth(midSale.getMonth() + 1);
  let publicSaleBonusUntil = formatDate(midSale);

  end.setMonth(end.getMonth() + 2);
  let publicSaleTo = formatDate(end);
  
  document.getElementById("privateSaleFrom").value = privateSaleFrom;
  document.getElementById("privateSaleTo").value = privateSaleTo;
  document.getElementById("privateSaleBonusEarlyUntil").value = privateSaleBonusUntil;

  document.getElementById("publicSaleFrom").value = publicSaleFrom;
  document.getElementById("publicSaleTo").value = publicSaleTo;
  document.getElementById("publicSaleBonusEarlyUntil").value = publicSaleBonusUntil;
}

function setupBonusSliders() {
  var sliders = [
    new Slider("#privateSaleBonus", {
      min: 0,
      max: 100,
      value: 45,
      step: 5,
    }),
    new Slider("#publicSaleBonus", {
      min: 0,
      max: 100,
      value: 20,
      step: 5,
    })
  ];

  sliders.forEach((slider, i) => {
    slider.on("change", () => { updateBonus(sliders, i); });
    updateBonus(sliders, i);
  })
}

function updateBonus(sliders,i) {
  document.getElementById(sliders[i].element.id + "Display").innerHTML =
    "+"+Math.floor(sliders[i].getValue()) + "%";
}

document.addEventListener("DOMContentLoaded", () => {
  deployToken = new DeployToken();

  $(".nextOnEnter").each(function() {
    this.addEventListener("keydown", (event) => {
      if(event.which == 13){
        var next = document.getElementById("next");
        next.click();
        event.preventDefault();
      }
    });
  });

  setupTokenTypeSelect();
  setupAllocationSliders();
  setupTokensaleDefaultDate();
  setupBonusSliders();

  document.getElementById("back").addEventListener("click", (event) => {
    previousSetupStep();
    event.preventDefault();
  });

  document.getElementById("next").addEventListener("click", (event) => {
    nextSetupStep();
    event.preventDefault();
  });

  document.getElementById("deploy").addEventListener("click", (event) => {
    nextSetupStep();
    event.preventDefault();
  });

  document.getElementById("redo").addEventListener("click",  (event) => {
    redo();
    event.preventDefault();
  });

  document.getElementById("symbol").addEventListener("change", (event) => {
    updateSymbol();
  });

  [ "privateSaleBonusFirstUntil", "publicSaleBonusFirstUntil" ].forEach((element) => {
    let item = document.getElementById(element);
    item.addEventListener("change", (event) => {
      item.value = formatSupply(parseSupply(item.value));
    });
  });

  var beforeCard = document.getElementById("step0");
  beforeCard.style.display="block";

  deployToken.readyPromise.then(() => {
    var owner = document.getElementById("owner");
    owner.innerHTML = formatAddress(deployToken.owner.address, true);

    var ethVault = document.getElementById("ethVault");
    ethVault.value = deployToken.owner.address.substring(2);
  });

  metamaskLoading.then((data) => {
    var loading = document.getElementById("loading");
    loading.style.display = "none";

    var setup = document.getElementById("setup");
    setup.style.display = "block";

    reloadOnMetamaskChanges();
  }).catch(() => {
    var loading = document.getElementById("loading");
    loading.style.display = "none";

    var metamask = document.getElementById("metamask");
    metamask.style.display = "block";
  });
  console.log("Started...");
});

window.previousSetupStep = previousSetupStep;
window.nextSetupStep = nextSetupStep;
window.checkInput = checkInput;
window.deployContracts = deployContracts;
